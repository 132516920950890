
:root {
    --fc-today-bg-color: rgba(243, 209, 209, 0.25);
    --fc-highlight-color: rgba(55, 255, 0, 0.522);
    /* --fc-highlight-color: var(--secondary-color) */
}
.fc .fc-multimonth {
    border: 0px;
}
.availability-calendar .selection ul {
    margin-left:40px;
}
.availability-calendar button.book {
    width: 100%
}
.availability-calendar div.note {
    text-align:center;
}
.availability-calendar .selection {
    margin: 0 0 40px 0;
    padding: 5px;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
}
.availability-calendar .fc-day.selected .fc-daygrid-day-number {
    color: #FFFFFF
}

/* We ensure days from other months are still visible */
.availability-calendar .fc-day.selected .fc-daygrid-day-top {
    opacity: 1;
}

/* .availability-calendar .fc-day.selected .fc-daygrid-bg-harness, */
.availability-calendar .fc-day.selected .fc-daygrid-bg-harness .fc-event {
    background: var(--secondary-color) !important; 
    opacity: 1;
}
.availability-calendar .fc-day.selected .fc-daygrid-bg-harness .fc-highlight {
    background: transparent !important;
}



.slider-swiper .swiper {
    display:flex;
    justify-content: center;
}
.slider-swiper .swiper .swiper-wrapper {
    flex-basis:content;
}



.contact-details .row:first-child {
  margin-top:0px;
}
.contact-details .row {
  margin-top:40px;
}
.contact-details .map {
  min-height:200px;
  height:100%;
}
.contact-details .details .contact address {
  margin-bottom:20px;
}
.contact-details .details .contact address span {
  display: inline-block;
  padding-top: 5px;
}
.contact-details .details .contact .contacts {
  margin-top:30px;
}
.contact-details .details .contact .contacts > div {
  margin-top:10px;
}
@media (max-width: 767.98px) {
.contact-details .details {
    padding:3rem;
}
.contact-details .details .contact,
  .contact-details .details .contact .contacts {
    display: flex;
    flex-wrap: wrap;
}
.contact-details .details .contact address,
  .contact-details .details .contact > div,
  .contact-details .details .contact .contacts > div {
    flex: 1 0 auto;
    margin-right:30px;
}
.contact-details .details .contact .contacts {
    flex:auto;
    margin-right:0px;
    margin-top:20px;
}
.contact-details .map {
    margin-top:20px;
}
}
.contact-details .identifiers, .contact-details .social-medias {
  margin-top:20px;
}
    




/* We add top margin between the content and the back to news link */
.apartment.container > div {
	margin-top:20px;
}
.apartment .swiper .apartment-picture {
	height: 400px;
}
.apartment .swiper .apartment-picture img {
	height: 400px;
}
.apartment .entry-title h1 {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.apartment .entry-image img {
	max-height:600px;
	width:auto;
}
.apartment .entry-content .description .media {
	display:block;
	max-width:800px;
}
.apartment .entry-content .description p {
	margin-bottom: 15px;
}
.apartment .entry-content .description ul {
	margin-left: 20px;
}
.apartment .entry-content .contact-details .details {
	padding-left: 0px;
}

/* We add some margin between share title and icons */
.apartment .si-share span {
    margin-right: 10px;
}
.apartment .si-share .social-icon {
    border-radius: 3px;
    margin: 0 5px 0 0
}


